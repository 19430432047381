import React from "react";
import { graphql } from 'gatsby';
import Img from "gatsby-image";
import Helmet from "react-helmet";
import ReactHtmlParser from "react-html-parser";

import Layout from "src/components/layout";
import SEO from "src/components/seo";
import Breadcrumb from "src/components/breadcrumb";
import NewsQuilt from "src/components/news-quilt.js";
import Gallery from "src/components/gallery/gallery";
import SingleFileDownload from "src/components/table-files-single";
import SocialMediaPanel from "src/components/social-media-panel";

// import Logo from "../images/logo-polb-update-final-alternate-2.png";


import 'babel-polyfill';

/**
 * Page template used by WordPress posts
 * (posts are used for news articles)
 */
class PostTemplate extends React.Component {

  constructor(props) {
    super(props);
    this.replaceWithComponents = this.replaceWithComponents.bind(this);
  }

  state = {
    postInfo: [],
    newsQuiltInfo: []
  }

  componentDidMount() {
    fetch(`https://thehelm.polb.com/wp-json/wp/v2/posts/${this.props.pageContext.wordpress_id}`)
      .then(response => response.json())
      .then(json => {
        let infoArray = [];
        if (!json.data) {
          infoArray.push(json);
          this.setState({ postInfo: infoArray });
        } else {
          console.log("Data did not load");
        }
      })

    fetch("https://thehelm.polb.com/wp-json/wp/v2/posts?categories_exclude=274&per_page=3")
      .then(response => response.json())
      .then(json => {
        if (json.length > 0) {
          // console.log(json);
          this.setState({ newsQuiltInfo: json });
        }
      })
  }

  /**
   * Replace HTML from WP content with React components. Specifically, for Envira Gallery.
   */
  replaceWithComponents(node, index) {
    // Replace Envira Gallery code with Gallery component
    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("envira-gallery-wrap") > -1)) {
      // const galleryInfo = node.children[0].attribs['data-gallery-images'];
      // return <Gallery key={index} galleryInfo={galleryInfo} previewMode="thumbs" />;

      let galleryConfig;

      for (let i = 0; i < node.children.length; i++) {
        if (node.children[i].attribs['data-gallery-config']) {
          galleryConfig = node.children[i];
          break;
        }
      }

      return <Gallery key={index} galleryConfig={galleryConfig.attribs['data-gallery-config']} galleryInfo={galleryConfig.attribs['data-gallery-images']} previewMode="thumbs" downloadable={galleryConfig}/>;
    }

    // Remove extra Envira Gallery code
    else if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("envira-gallery-theme-base") > -1)) {
      return null;
    }

    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("wpfd-single-file") > -1)) {
      return <SingleFileDownload key={index} data={node} />
    }
  }

  render() {
    const postData = this.state.postInfo[0];
    // const newsData = this.props.data.allWordpressPost;

    let monthArray = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let normalDate = postData ? postData.date : "";
    let modifiedDate = "";
    let dateSplit = normalDate.length > 0 ? normalDate.split("T") : [];
    let dayMonthYearSplit = dateSplit.length > 0 ? dateSplit[0].split("-") : [];
    if (postData) {
      if (dayMonthYearSplit[2][0] === "0") {
        let dateDay = dayMonthYearSplit[2];
        let modifiedDateDay = dateDay.replace("0", "");
        let monthIndex = dayMonthYearSplit[1] - 1;
        modifiedDate = `${monthArray[monthIndex]} ${modifiedDateDay}, ${dayMonthYearSplit[0]}`;
      } else {
        let dateDay = dayMonthYearSplit[2];
        let monthIndex = dayMonthYearSplit[1] - 1;
        modifiedDate = `${monthArray[monthIndex]} ${dateDay}, ${dayMonthYearSplit[0]}`;
      }
    }

    const urlLink = postData && `https://polb.com/port-info/news-and-press${postData.link.split(".com")[1]}`;
    // const testLink = "/port-info/news-and-press/zero-emissions-yard-equipment-enters-port-service-02-25-2020/";
    // const testLinkArray = testLink.substring(26).split("-");
    // const testLinkArrayLength = testLinkArray.length;

    // console.log(testLinkArray);

    // for (let index = testLinkArray.length - 1; index >= testLinkArrayLength - 3; index--) {
    //   testLinkArray.splice(index, 1);
    // }

    // const title = testLinkArray.join(" ");
    // const correctTitle = title.charAt(0).toUpperCase() + title.slice(1);

    // console.log(this.props.pageContext);

    // fields needed: path for url, image for thumbnail, title, news_excerpt for descrip

    const ogData = this.props.data.wordpressPost;
    const ogTitle = ogData.title;
    const ogImage = "https://thehelm.polb.com/wp-content/uploads/SotP_PWP_Hero1920x1080black2.jpg";
    const ogUrl = `https://polb.com/port-info/news-and-press${ogData.path}`;
    const ogDescription = ogData.acf.news_excerpt;

    // console.log(ogData);

    return (
      <React.Fragment>
        <Helmet>
          <meta property="og:title" content={ogTitle} />
          <meta property="og:image" content={ogImage} />
          <meta property="og:url" content={ogUrl} />
          <meta property="og:description" content={ogDescription} />
          <meta name="twitter:image" content={ogImage} />
          <meta name="twitter:title" content={ogTitle} />
          <meta name="twitter:description" content={ogDescription} />
        </Helmet>
        {postData ?
          <Layout hasHero={false}>
            <SEO title={postData.title.rendered.replace(/&#8216;/, "'").replace(/&#8217;/, "'")} description={postData.excerpt.rendered} url={urlLink} />

            <Helmet>
              <body className="no-hero" />
            </Helmet>

            <Breadcrumb categories={postData.categories} pageTitle={postData.title.rendered} />

            {
              postData.categories && postData.categories[0] !== 274 &&
              <SocialMediaPanel data={postData} />
            }

            <article className="news gutter">
              <h1 className="news__heading" dangerouslySetInnerHTML={{ __html: postData.title.rendered }} />

              {
                postData.categories && postData.categories[0] !== 274 &&
                <div className="content content--date" dangerouslySetInnerHTML={{ __html: modifiedDate }} />
              }

              {/*{
                (postData.acf && postData.acf.cover_image) &&
                <Img fluid={postData.acf.cover_image.localFile.childImageSharp.fluid} />
              }*/}

              {
                (postData && postData.acf && postData.acf.cover_image) &&
                <img src={postData.acf.cover_image} />
              }

              {
                (postData.content.rendered.indexOf('envira-gallery-wrap') > -1) ||
                  (postData.content.rendered.indexOf('wpfd-single-file') > -1)
                  ?
                  <div className="content content--news">
                    {ReactHtmlParser(postData.content.rendered, { transform: this.replaceWithComponents })}
                  </div>
                  :
                  <div className="content content--news" dangerouslySetInnerHTML={{ __html: postData.content.rendered }} />
              }

            </article>
            {
              this.state.newsQuiltInfo.length > 0 &&
              <NewsQuilt newsQuiltData={this.state.newsQuiltInfo} quiltStyle="pattern-01" />
            }
          </Layout>
          :
          null
        }
      </React.Fragment>
    )
  }
}

export default PostTemplate;



export const pageQuery = graphql`
  query currentPostQuery($id: String!) {
    wordpressPost(id: { eq: $id }) {
      id
      wordpress_id
      title
      path
      acf {
        news_excerpt
      }
    }
  }
`;























// import React from "react";
// import {graphql} from 'gatsby';
// import Img from "gatsby-image";
// import Helmet from "react-helmet";
// import ReactHtmlParser from "react-html-parser";

// import Layout from "src/components/layout";
// import SEO from "src/components/seo";
// import Breadcrumb from "src/components/breadcrumb";
// import NewsQuilt from "src/components/news-quilt.js";
// import Gallery from "src/components/gallery/gallery";
// import SingleFileDownload from "src/components/table-files-single";


// import 'babel-polyfill';

// /**
//  * Page template used by WordPress posts
//  * (posts are used for news articles)
//  */
// class PostTemplate extends React.Component {

//   constructor(props) {
//     super(props);
//     this.replaceWithComponents = this.replaceWithComponents.bind(this);
//   }

//   /**
//    * Replace HTML from WP content with React components. Specifically, for Envira Gallery.
//    */
//   replaceWithComponents(node, index) {
//     // Replace Envira Gallery code with Gallery component
//     if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("envira-gallery-wrap") > -1)) {
//       const galleryInfo = node.children[0].attribs['data-gallery-images'];
//       return <Gallery key={index} galleryInfo={galleryInfo} previewMode="thumbs"/>;
//     }

//     // Remove extra Envira Gallery code
//     else if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("envira-gallery-theme-base") > -1)) {
//       return null;
//     }

//     if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("wpfd-single-file") > -1)) {
//       return <SingleFileDownload key={index} data={node}/>
//     }
//   }

//   render() {
//     const postData = this.props.data.wordpressPost;
//     const newsData = this.props.data.allWordpressPost;
//     // console.log(newsData);

//     let normalDate = postData.date;
//     let modifiedDate = "";
//     let dateSplit = normalDate.split(" ");
//     if (dateSplit[1][0] === "0") {
//       let dateDay = dateSplit[1];
//       let modifiedDateDay = dateDay.replace("0", "");
//       modifiedDate = `${dateSplit[0]} ${modifiedDateDay} ${dateSplit[2]}`;
//     } else {
//       modifiedDate = postData.date;
//     }



//     return (
//       <Layout hasHero={false}>
//         <SEO title={postData.title} description=""/>

//         <Helmet>
//           <body className="no-hero"/>
//         </Helmet>

//         <Breadcrumb categories={postData.categories} pageTitle={postData.title}/>

//         <article className="news gutter">
//           <h1 className="news__heading" dangerouslySetInnerHTML={{__html: postData.title}}/>

//           {
//             postData.categories && postData.categories[0].name !== "Profile"  &&
//             <div className="content content--date" dangerouslySetInnerHTML={{__html: modifiedDate}}/>
//           }

//           {
//             (postData.acf && postData.acf.cover_image) &&
//             <Img fluid={postData.acf.cover_image.localFile.childImageSharp.fluid}/>
//           }

//           {
//             (postData.content.indexOf('envira-gallery-wrap') > -1) ||
//             (postData.content.indexOf('wpfd-single-file') > -1)
//               ?
//               <div className="content content--news">
//                 {ReactHtmlParser(postData.content, {transform: this.replaceWithComponents})}
//               </div>
//               :
//               <div className="content content--news" dangerouslySetInnerHTML={{__html: postData.content}}/>
//           }

//         </article>
//         {/* <NewsQuilt newsQuiltData={newsData} quiltStyle="pattern-01"/> */}
//       </Layout>
//     )
//   }
// }

// export default PostTemplate;

// export const pageQuery = graphql`
//   query currentPostQuery($id: String!) {
//     wordpressPost(id: { eq: $id }) {
//       title
//       categories {
//         name
//         slug
//       }
//       content
//       date(formatString: "MMMM DD, YYYY")
//       acf {
//         cover_image {
//           localFile {
//             childImageSharp {
//               fluid(maxWidth: 1170) {
//                 ...GatsbyImageSharpFluid
//               }
//             }
//           }
//         }
//       }
//     }

//     allWordpressPost(
//       filter: {
//         categories: {
//           elemMatch: {
//             name: {
//               nin: ["Profile"]
//             }
//           }
//         }
//       }
//       limit: 3
//       sort: {fields: [date], order: DESC}
//     ) {
//       edges {
//         node {
//           ...NewsQuilt
//         }
//       }
//     }
//   }
// `;
